const plusBen = document.querySelector('#plusButton')
const minusBen = document.querySelector('#minusButton')
const plusContainer = document.querySelector('#plusCOntainer')
const minusContainer = document.querySelector('#minusContainer')

plusBen.addEventListener('click', () => {
    plusBen.classList.remove('opacity-button')
    minusBen.classList.add('opacity-button')
    plusContainer.classList.add('opacity-container')
    plusContainer.classList.remove('opacity-container-show')
    minusContainer.classList.remove('opacity-container')
    minusContainer.classList.add('opacity-container-show')
})

minusBen.addEventListener('click', () => {
    plusBen.classList.add('opacity-button')
    minusBen.classList.remove('opacity-button')
    plusContainer.classList.remove('opacity-container')
    plusContainer.classList.add('opacity-container-show')
    minusContainer.classList.add('opacity-container')
})
